import sendSentryError from "./sendSentryError";

export default (json, isCompleted, generateDocuments) => {
  var formData = new FormData();
  var url = document.getElementById("surveyContainer").getAttribute("data-response-url");
  formData.append("payload", JSON.stringify(json));
  formData.append("isCompleted", isCompleted);
  formData.append("generateDocuments", generateDocuments);
  const request = new XMLHttpRequest();
  try {
    request.onreadystatechange = function (oEvent) {
      // Only run this if the request is complete because each partial will send full data so things might work on the next page
      if (
        request.readyState === 4 &&
        request.status !== 200 &&
        request.status !== 204 &&
        isCompleted
      ) {
        sendSentryError(
          "Server failed to save request. HTTP Code: " +
            request.status +
            ". Status Text: " +
            request.statusText +
            ". URL: " +
            url
        );
        alert(
          "Something went wrong while saving your data. Please try again later, or ask your arranger to save your data."
        );
        window.location.reload();
      }
    };

    request.open("POST", url);
    request.setRequestHeader(
      "X-CSRF-Token",
      document.querySelector("meta[name=csrf-token]")?.content
    );
    request.send(formData);
  } catch (err) {
    sendSentryError("Server can't be contacted. Error: " + err + ". URL: " + url);
    alert(
      "Something went wrong while saving your data. Please try again later, or ask your arranger to save your data."
    );
    window.location.reload();
  }
};
