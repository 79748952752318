import addPhotoUpload from "./addPhotoUpload";
import addStyling from "./addStyling";
import getDocuments from "./getDocuments";
import sendLargeDataToServer from "./sendLargeDataToServer";
import handlePartialSend from "../form_events/handlePartialSend";
import addReviewStyling from "./addReviewStyling";

export default async (survey, options) => {
  addStyling();
  let state = document.getElementById("surveyContainer").getAttribute("data-response-state");
  if (
    options.question.name.includes("review_stage_") ||
    ((state === "NSW" || state === "WA") && options.question.name.includes("applicant_id_"))
  ) {
    survey.showNavigationButtons = "none";
  } else {
    survey.showNavigationButtons = "bottom";
  }
  // File upload questions.
  if (
    options.question.name === "review_stage_applicant_signature_photo" ||
    options.question.name === "applicant_signature_photo" ||
    options.question.name === "applicant_id_photo" ||
    options.question.name === "applicant_signature_uploaded_file" ||
    options.question.name === "applicant_id_uploaded_file"
  ) {
    const questionElement = document.querySelector(`[data-name="${options.question.name}"]`);
    if (questionElement.querySelector(".sd-file__image-wrapper > img")) { 
      questionElement.querySelector(".sd-file__image-wrapper > img").src = _getPhotoIdInput();
    }
    if (
      survey.state !== "preview" &&
      (options.question.name === "review_stage_applicant_signature_photo" ||
        options.question.name === "applicant_signature_photo" ||
        options.question.name === "applicant_id_photo")
    ) {
      addPhotoUpload(survey, options);
    }
  }
  // Upload Photo ID page
  if (options.question.name === "applicant_id_upload_type") {
    const yesButton = document.querySelector(".button--primary");
    const hasSurveyValue = Boolean(survey.getValue(options.question.name));
    if (!hasSurveyValue && yesButton) yesButton.classList.add("disabled");

    yesButton?.addEventListener("click", () => {
      handlePartialSend(survey);
      const expectedUpload =
        survey.getValue("applicant_id_upload_type") === "take_photo"
          ? "applicant_id_photo"
          : "applicant_id_uploaded_file";

      sendLargeDataToServer(survey, expectedUpload);
    });
  }
  // Review PDFs page.
  if (options.question.name === "review_stage_pdfs") {
    handlePartialSend(survey, true);
    getDocuments(options);
    const yesButton = document.querySelector(".button--primary");
    yesButton.addEventListener("click", (event) => {
      survey.nextPage();
    });
  }

  if (
    ["SA", "NSW", "QLD", "VIC", "ACT", "TAS"].includes(state) &&
    options.question.name == "ashes_delivery"
  ) {
    _updateAshesDelivery(survey, state);
  }

  if (options.question.name == "end_of_form_review") {
    addReviewStyling(survey);
  }

  // Add default value to question
  var defaultResponse = JSON.parse(document.getElementById("surveyContainer").getAttribute("data-default-response"));

  $.each(defaultResponse, function(name, val) {
    var question = survey.getQuestionByName(name);
    if (question) {
      question.defaultValue = val;
    }
  });
};

function _getPhotoIdInput() {
  return survey.getValue("applicant_id_upload_type") === "take_photo"
    ? survey.getValue("applicant_id_photo")[0].content
    : survey.getValue("applicant_id_uploaded_file")[0].content;
}

function _updateAshesDelivery(survey, state) {
  _setQuestionValueOnItem(
    survey,
    "ashes_delivery",
    "recipient_name_first",
    survey.data.applicant_name.first
  );
  _setQuestionValueOnItem(
    survey,
    "ashes_delivery",
    "recipient_name_last",
    survey.data.applicant_name.last
  );
  _setQuestionValueOnItem(survey, "ashes_delivery", "recipient_phone", survey.data.applicant_phone);
  _setQuestionValueOnItem(survey, "ashes_delivery", "street", survey.data.applicant_address.street);
  _setQuestionValueOnItem(survey, "ashes_delivery", "suburb", survey.data.applicant_address.suburb);
  _setQuestionValueOnItem(survey, "ashes_delivery", "state", survey.data.applicant_address.state);
  _setQuestionValueOnItem(
    survey,
    "ashes_delivery",
    "postcode",
    survey.data.applicant_address.postcode
  );
}

function _setQuestionValueOnItem(survey, parentName, questionName, value) {
  let question = null;
  let items = survey.getQuestionByName(parentName).items;
  if (items.length > 0) {
    question = items.find((i) => i.name == questionName);
    if (
      question &&
      (question.value === undefined || question.value === null || question.value === "")
    ) {
      question.value = value;
    }
  }
}
